<template>
  <div class="register">
    <img src="../../assets/back_icon.png" @click="$router.go(-1)" class="back_icon">
    <img src="../../assets/login_logo_back.png" class="signup_logo">
    <div class="title">{{ $t('register') }}</div>
    <div class="content">
      <div class="item">
        <img src="../../assets/phone_icon.png" class="icon">
        <mu-text-field
          :label="$t('phone')"
          v-model="form.phone"
          type="text"
          label-float
          full-width
          color="#F4F4F4"
          :error-text="isCheck? validation(['phone']): ''"
          v-mask="'#### #### ##'"
        />
      </div>
      <div class="item">
        <img src="../../assets/password_icon.png" class="icon">
        <mu-text-field
          :label="$t('password')"
          v-model="form.password"
          :type="eye_type"
          label-float
          full-width
          max-length="4"
          oninput = "value=value.replace(/[^\d]/g,'')"
          :error-text="isCheck? validation(['password']): ''"
          color="#F4F4F4">
          <img v-if="eye_type == 'password'" slot="append" src="../../assets/eye_icon.png" class="append_icon" @click="eye_type = 'test'">
          <img v-else slot="append" src="../../assets/eye_show_icon.png" class="append_icon" @click="eye_type = 'password'">
        </mu-text-field>
      </div>
      <div class="item">
        <img src="../../assets/password_icon.png" class="icon">
        <mu-text-field
          :label="$t('confirmPassword')"
          v-model="form.comfirmPassword"
          :type="eye_type_comfirm"
          label-float
          full-width
          max-length="4"
          oninput = "value=value.replace(/[^\d]/g,'')"
          :error-text="isCheck? validation(['comfirmPassword']): ''"
          color="#F4F4F4">
          <img v-if="eye_type_comfirm == 'password'" slot="append" src="../../assets/eye_icon.png" class="append_icon" @click="eye_type_comfirm = 'test'">
          <img v-else slot="append" src="../../assets/eye_show_icon.png" class="append_icon" @click="eye_type_comfirm = 'password'">
        </mu-text-field>
      </div>
    </div>
    <div class="buttons">
      <read-view v-model="checked" :src="privacyUrl" :title="$t('serviceAgreement')" />
      <mu-button
          full-width
          color="#E7AD5C"
          text-color="#18252A"
          @click="submit"
        >
          {{ $t("register") }}
        </mu-button>
    </div>
    <div class="login">
      {{ $t('loginTips') }}
      <div class="btn" @click="onLogin">
        {{ $t('loginNow') }}
      </div>
    </div>
  </div>
</template>

<script>
import { phoneRules, validateCodeRules, passwordRules, comfirmPasswordRules } from "../../utils/rules";
import { mapState, mapMutations } from "vuex";
import readView from '@/components/readView.vue'
import mixin from './mixin'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'Register',
  components: {
    readView,
  },
  mixins: [mixin],
  props: ['value'],
  data() {
    return {
      eye_type: 'password',
      eye_type_comfirm: 'password',
      checked: false,
    }
  },
  computed: {
    ...mapState(["record", "brand"]),
    privacyUrl() {
      if (this.brand.channelInfo) {
        return this.brand.channelInfo.privacyUrl
      }
      return ''
    },
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "registerPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "registerPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
    comfirmPasswordState() {
      this.SET_RECORD_ITEM({
        key: "comfirmRegisterPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPasswordRules(this.form.comfirmPassword, this.form.password) : null;
    }
  },
  watch: {
    checked(val) {
      this.SET_CHECKED_SERVICE_AGREEMENT(val)
    }
  },
  created() {
    gtmPageView(this)
    this.checked = this.$store.state.checkedServiceAgreement || false;
    this.form.phone = this.record.registerPhone || "";
    this.form.password = this.record.registerPassword || "";
    this.form.comfirmPassword = this.record.comfirmRegisterPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM", "SET_ACCESS_TOKEN", "SET_CHECKED_SERVICE_AGREEMENT"]),
    onLogin() {
      this.SET_RECORD_ITEM({
        key: "loginPhone",
        value: this.form.phone,
      });
      this.$router.back(-1)
    },
    async submit() {
      if (!this.checked) {
        this.$toast(this.$t('readViewTips2'));
        return;
      }
      this.isCheck = true;
      var error = this.validation(['phone', 'password']);
      if (error) return this.$toast(error);
      this.SET_RECORD_ITEM({
        key: "codeType",
        value: "REGISTER",
      });
      this.SET_RECORD_ITEM({
        key: "codePhone",
        value: this.form.phone,
      });
      this.SET_RECORD_ITEM({
        key: "codePassword",
        value: this.form.password,
      });
      this.SET_RECORD_ITEM({
        key: "codeComfirmPassword",
        value: this.form.comfirmPassword,
      });
      this.$router.push({path: '/user/code'});
    },
  }
}
</script>

<style lang="scss" scoped>
.register {
  background: #18252A;
  overflow: auto;
  .back_icon {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 20px;
  }
  .signup_logo {
    height: 210px;
    display: block;
    margin: 0 auto;
    margin-top: 50px;
  }
  .title {
    text-align: left;
    margin-top: 20px;
    font-size: 24px;
    color: #E7AD5C;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
  }
  .content {
    padding: 10px 10px 0px 10px;
    .item {
      display: flex;
      margin: 0px 20px 0px 20px;
      color: #F4F4F4;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 5px;
        margin-top: 35px;
      }
      .append_icon {
        width: 20px;
        margin-right: 9px;
      }
      .mu-input {
        color: #F4F4F4;
        flex: 1;
      }
      /deep/ .mu-text-field-input {
        color: #F4F4F4;
        height: 38px;
      }
      /deep/ .mu-input-label {
        color: #F4F4F4;
      }
      /deep/ .mu-input-line {
        background-color: #F4F4F4;
      }
      /deep/ .mu-input.has-label {
        padding-top: 28px;
        padding-bottom: 4px;
        min-height: 70px;
      }
    }
  }
  .buttons {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    /deep/ .mu-raised-button {
      border-radius: 8px;
      height: 50px;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
  .login {
    text-align: center;
    padding-bottom: 20px;
    color: #F4F4F4;
    .btn {
      color: #E7AD5C;
      text-decoration: underline;
    }
  }
}
</style>
